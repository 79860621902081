import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastComponent } from '../common/elements/toast/toast.component';

export interface ToastData {
  action: 'Success' | 'Error' | 'Warning';
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private _snackBar: MatSnackBar) {}

  callToast({ message, action }: ToastData) {
    this._snackBar.openFromComponent(ToastComponent, {
      duration: 3000,
      data: { message, action },
      panelClass: ['custom-toast', action],
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }
}
