export enum IconReferences {
  'jaguar' = './assets/img/products/jaguar.svg',
  'cheetah' = './assets/img/products/cheetah.svg',
  'eagle' = './assets/img/products/eagle.svg',
  'falcon' = './assets/img/products/falcon.svg',
  'leopard' = './assets/img/products/leopard.svg',
  'sailfish' = './assets/img/products/sailfish.svg',
  'swift' = './assets/img/products/swift.svg',
  'swordfish' = './assets/img/products/swordfish.svg',
  'yellowfin' = './assets/img/products/yellowfin.svg',
  'dev' = './assets/img/products/dev.svg',
  'custom' = './assets/img/products/custom.svg',
}
