import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  $loaderDisplay: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  startLoading() {
    this.$loaderDisplay.next(true);
    setInterval(() => {
      this.$loaderDisplay.next(false);
    }, 10000);
  }

  stopLoading() {
    this.$loaderDisplay.next(false);
  }
}
