import { IAPICurrency } from './currency';
import { APIClient } from '../../services/clients.service';

export interface APIProject {
  id: string;
  name: string;
  status: ProjectStatus;
  logo?: string;
  dueDateOffset: number;
  international: boolean;
  client: APIClient;
  currency: IAPICurrency;
}

export enum ProjectStatus {
  OPEN = 'Open',
  SUSPENDED = 'Suspended',
  CLOSED = 'Closed',
}
