import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { lastValueFrom } from 'rxjs';
import { IAPIUser, IUserBaseParams } from '../common/interfaces/user';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(public http: HttpClient) {}
  private cachedUser: IAPIUser | null = null;
  private storageKey = 'currentUser';

  getUsers(): Promise<IAPIUser[]> {
    return lastValueFrom<IAPIUser[]>(this.http.get<IAPIUser[]>(environment.baseUrl + '/users'));
  }

  async me(): Promise<IAPIUser> {
    if (this.cachedUser) {
      return Promise.resolve(this.cachedUser);
    }

    const user = await lastValueFrom(this.http.get<IAPIUser>(environment.baseUrl + '/users/me'));
    this.cachedUser = user;
    return user;
  }

  getUserById(id: string): Promise<IAPIUser> {
    return lastValueFrom<IAPIUser>(this.http.get<IAPIUser>(environment.baseUrl + `/users/${id}`));
  }

  createUser(data: IUserBaseParams) {
    return lastValueFrom(this.http.post<IAPIUser>(environment.baseUrl + '/users', data));
  }

  editUser(id: string, data: IUserBaseParams) {
    return lastValueFrom(this.http.put<IAPIUser>(environment.baseUrl + `/users/${id}`, data));
  }

  async deleteUser(id: string) {
    return await lastValueFrom(this.http.delete(environment.baseUrl + `/users/${id}`));
  }

  getUserByEmail(email: string): Promise<IAPIUser> {
    return lastValueFrom<IAPIUser>(this.http.get<IAPIUser>(environment.baseUrl + `/users/email/${email}`));
  }

  clearCache() {
    localStorage.removeItem(this.storageKey);
  }
}
