import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { ToastData } from 'src/app/services/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent {
  icon: 'check_circle' | 'cancel' | 'info' = 'check_circle';

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: ToastData) {
    if (data.action === 'Success') this.icon = 'check_circle';
    else if (data.action === 'Error') this.icon = 'cancel';
    else this.icon = 'info';
  }
}
