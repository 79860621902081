import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { UsersService } from '../../services/users.service';
import { Role } from '../interfaces/user';

export const roleGuard: CanActivateFn = async () => {
  const router = inject(Router);
  const usersService = inject(UsersService);

  try {
    const user = await usersService.me();
    if (user && user.role !== Role.VIEWER) {
      return true;
    } else {
      router.navigate(['/all-invoices']);
      return false;
    }
  } catch (error) {
    router.navigate(['/login']);
    return false;
  }
};
