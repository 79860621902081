<div class="header">
  <button class="menu-toggle" (click)="toggleMenu()">
    <mat-icon *ngIf="isDrawerOpened">menu</mat-icon>
    <mat-icon *ngIf="!isDrawerOpened">close</mat-icon>
  </button>
  <p class="title">[BillyFy]™ {{ devTitle }}</p>
</div>
<mat-drawer-container>
  <mat-drawer *ngIf="router.url !== '/login'" #drawer class="drawer" mode="side" opened>
    <app-side-bar (optionSelected)="closeDrawerOnOptionSelected()"></app-side-bar>
  </mat-drawer>
  <mat-drawer-content class="content">
    <!-- <mat-progress-bar *ngIf="showLoader" mode="indeterminate"></mat-progress-bar> -->
    <app-top-bar *ngIf="router.url !== '/login'"></app-top-bar>
    <main>
      <router-outlet></router-outlet>
    </main>
  </mat-drawer-content>
</mat-drawer-container>
