export enum Role {
  ADMIN = 'Admin',
  OPERATOR = 'Operator',
  VIEWER = 'Viewer',
}

export interface IUserBaseParams {
  name: string;
  email: string;
  role: Role;
}

export interface IAPIUser extends IUserBaseParams {
  id: string;
}

export interface IUserDialogClose extends IUserBaseParams {
  id?: string;
}

export interface IUserDialogOpen {
  edit: boolean;
  user: IAPIUser;
}
