import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, last, tap } from 'rxjs/operators';
import { ToastService } from 'src/app/services/toast.service';

@Injectable()
export class ToastResponseInterceptor implements HttpInterceptor {
  constructor(private toastService: ToastService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.method === 'GET') {
      return next.handle(request);
    } else {
      return next.handle(request).pipe(
        last(),
        tap((res) => {
          this.toastService.callToast({
            action: 'Success',
            message: `${this.getApiGoal(request.url)} ${this.getApiMethod(request.method)} Successfully!`,
          });
          return res;
        }),
        catchError((error: HttpErrorResponse) => {
          const errMsg = error?.error[0]?.error ?? error.error.error; //FIXME the errors should have the same pattern
          this.toastService.callToast({
            action: 'Error',
            message: errMsg,
          });
          throw new Error(errMsg);
        }),
      );
    }
  }

  getApiGoal(url: string) {
    if (url.includes('/products')) return 'Product';
    else if (url.includes('/clients')) return 'Client';
    else if (url.includes('/schedules/test')) return 'Schedule Test';
    else if (url.includes('/invoices/')) return 'Invoice';
    else if (url.includes('/invoices/regenerate')) return 'Invoice Regeneration Queue';
    else if (url.includes('/recipients')) return 'Recipient';
    else if (url.includes('/projects') && !url.includes('/schedule/autoSend')) return 'Project';
    else if (url.includes('/users')) return 'Users';
    else if (url.includes('/sprints')) return 'Sprint';
    else return 'Schedule';
  }

  getApiMethod(m: string) {
    switch (m) {
      case 'DELETE':
        return 'Deleted';
      case 'POST':
        return 'Created';
      default:
        return 'Updated';
    }
  }
}
