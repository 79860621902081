export const environment = {
  production: false,
  firebase: {
    projectId: 'aoa-labs',
    appId: '1:1078604004638:web:14942aec5745f666de9d0f',
    storageBucket: 'aoa-labs.appspot.com',
    apiKey: 'AIzaSyCD0I-7lJbX2JkwedVuz87Fil4BlmBcg7s',
    authDomain: 'aoa-labs.firebaseapp.com',
    messagingSenderId: '1078604004638',
    measurementId: 'G-ML4XK9XGL6',
  },
  baseUrl: 'https://api-dev.billyfy.com',
  // baseUrl: 'http://localhost:3000',
  authToken:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiYWJ1ZGFiYSIsImlhdCI6MTY3NTExNTE2OH0.Xcxcz7preBKTU5ROnk8nPO_Mo2WVLStd1AMXyUKZPjY',
};
